<template>
  <div class="search-result">
    <!-- 搜索栏 -->
    <div class="part-1">
      <van-icon name="arrow-left" size="0.55rem" @click="toSearchPage" />
      <div class="search-box">
        <van-search v-model="keywords" shape="round" placeholder="搜索课程" @focus="toSearchPage" />
        <van-button class="search-button" round color="linear-gradient(90deg, #5abffe 0%, #389cf8 61%, #1578f2 100%)">搜索</van-button>
      </div>
    </div>
    <div class="part-2">
      <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
        <ul v-for="(item, index) in courseList" :key="index" @click="gotoPage(item)">
          <li>
            <div class="img-box">
              <img :src="item.source.logoFile" />
            </div>
          </li>
          <li>
            <div>
              <span>{{ item.type === 'Live' ? '直播' : item.source.type !== 0 ? '讲义' : '视频' }}</span>
              <h5 v-if="!item.highlight">{{ item.source.title }}</h5>
              <h5 v-else-if="item.highlight && item.highlight.title" v-html="item.highlight.title[0]"></h5>
              <h5 v-else>{{ item.source.title }}</h5>
            </div>
            <p v-if="!item.highlight">{{ item.source.summary }}</p>
            <p v-else-if="item.highlight && item.highlight.summary" v-html="item.highlight.summary[0]"></p>
            <p v-else>{{ item.source.summary }}</p>
          </li>
          <li v-if="item.source.isFree === 1">
            <div @click.stop="gotoPage(item)" style="text-align:center">免费</div>
          </li>
          <li v-else-if="item.source.hasPermission !== 'Y'">
            <template v-if="item.source.isForbid === 0">
              <strike v-if="isVip">￥{{ item.source.sellPrice }}</strike>
              <strong><span>￥</span>{{ isVip ? item.source.memberPrice : item.source.sellPrice }}</strong>
              <div @click.stop="gotoPay(item)">购买</div>
            </template>
            <template v-else>
              <span style="font-size: 0.4rem;">会员专享</span>
            </template>
          </li>
          <li v-else style="justify-content: center">
            <div @click.stop="gotoPage(item)" style="text-align:center">进入学习</div>
          </li>
        </ul>
      </van-list>
    </div>
    <van-dialog v-model="show" title="提示" show-cancel-button @cancel="show = false" @confirm="$router.push({ path: `/allCourses/order/${item.id}?type=LIVE` })">
      <p style="text-align: center;">此直播未购买，是否购买</p>
    </van-dialog>
  </div>
</template>
<script>
import './searchResult.less'
export default {
  data() {
    return {
      loading: false,
      finished: false,
      keywords: this.$route.query.keywords, // 搜索关键字
      courseList: [], // 课程列表
      item: {},
      show: false,
      isVip: true,
      isBack: '',
    }
  },
  created() {
    if (localStorage.userInfo) {
      let info = JSON.parse(localStorage.userInfo)
      this.isVip = info.isVip && (info.enterpriseName || info.enterpriseShorthand) ? true : false
    }
    this.isBack = this.$route.query.isBack
    // 禁用物理返回键（勿删）
    if (this.isBack === 'Y') {
      history.pushState(null, null, document.URL)
      window.addEventListener('popstate', this.pushHistory, false)
    }
  },
  methods: {
    pushHistory() {
      console.log('禁用按钮的监听')
      if (this.isBack === 'Y') {
        history.pushState(null, null, document.URL)
        this.$Toast.fail('请点击左上角的返回键进行返回')
      }
    },
    gotoPay(item) {
      let path = `/allCourses/order/${item.id}`
      if (item.type === 'Live') {
        path = `/allCourses/order/${item.id}?type=LIVE`
      }
      this.$router.push({
        path: path,
      })
    },
    gotoPage(item) {
      let path = `/allCourses/details/${item.id}/curriculum`
      if (item.source.type !== 0) {
        path = `/allCourses/courseHandout/${item.id}`
      } else {
        path = path + '?backUrl=' + encodeURIComponent(encodeURIComponent(this.$router.history.current.fullPath))
      }
      // let temp = this.$router.history.current.fullPath
      // localStorage.back_url = temp
      if (item.type === 'Live') {
        path = `/allCourses/listDetails/${item.id}`
      }
      this.$router.push({ path: path })
    },
    onLoad() {
      this.$axios
        .get(`/course/front/permit/query?q=${encodeURIComponent(encodeURIComponent(this.keywords))}`)
        .then((res) => {
          if (res.data.status === 1) {
            this.courseList = res.data.data.hits
            this.finished = true
          } else {
            this.$Toast.fail(res.data.message)
          }
          this.loading = false
        })
        .catch((e) => {
          this.loading = false
          this.$Toast.fail('服务器开小差了，稍后重试')
          return e
        })
    },
    // 跳转至搜索页
    toSearchPage() {
      let query = {
        keywords: this.keywords,
      }
      if (this.isBack === 'Y') {
        query.isBack = 'Y'
      }
      this.$router.replace({
        name: 'search',
        query: query,
      })
    },
  },
  destroyed() {
    window.removeEventListener('popstate', this.pushHistory, false)
  },
}
</script>
